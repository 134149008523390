<template>
  <div class="mortgage--project" v-if="project">
    <div class="mortgage--project-image m-auto">
      <img :src="primaryPhoto" />
    </div>
    <div class="py-3 px-4" style="width: 100%">
      <div class="mortgage--project-price mb-2">
        {{ price }}
      </div>
      <div class="mortgage--project-title">{{ projectTitle }}</div>
      <div class="mortgage--project-type">
        {{ $t('general.propertyType') }}: {{ project.property_type }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['project', 'unitType'],
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
    primaryPhoto() {
      if (this.unitType && this.unitType.photos && this.unitType.photos.length > 0) {
        for (let photo of this.unitType.photos) {
          if (photo.is_primary_photo) {
            return photo.url;
          }
        }
      }
      if (
        this.project &&
        this.project.photos &&
        this.project.photos.data &&
        this.project.photos.data.length > 0
      ) {
        for (let photo of this.project.photos.data) {
          if (photo.is_primary_photo) {
            return photo.url;
          }
        }
      } else if (this.project && this.project.photos && this.project.photos.length > 0) {
        for (let photo of this.project.photos) {
          if (photo.is_primary_photo) {
            return photo.url;
          }
        }
      }
      return '';
    },
    price() {
      if (this.unitType) {
        return this.$n(this.unitType.min_price_num, 'currency', 'id-ID');
      }
      if (this.project) {
        let price =
          this.project.listing_type === this.listingTypeEnum.SECONDARY
            ? this.project.price
            : this.project.min_price_num;
        return this.$n(price, 'currency', 'id-ID');
      }
      return '';
    },
    projectTitle() {
      if (this.unitType) {
        return this.unitType.title;
      }
      if (this.project) {
        return this.project.listing_type === this.listingTypeEnum.SECONDARY
          ? this.project.title
          : this.project.project_name;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.mortgage--project {
  width: 100%;
  display: flex;
  border-radius: 16px;
  box-shadow: $box-shadow-hover;

  .mortgage--project-image {
    img {
      width: 150px;
      height: 100%;
      object-fit: cover;
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }
  }

  .mortgage--project-price {
    font-size: $font-xl;
  }

  .mortgage--project-title {
    font-size: $font-md;
    color: #666666;
  }

  .mortgage--project-type {
    font-size: $font-sm;
  }
}
</style>
